import "./dashboard.css";
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from "notistack";
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import { Button, Toolbar, Typography, Menu, MenuItem } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import KmmService from "../kmm-service/src/";
import { Fetch } from "../storage";
import Grid from '@material-ui/core/Grid';

import {
	ClienteCard
} from "./cards/cards";

const styles = {
	content: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto',
	},
	appBar: {
		zIndex: 9
	},
	toolbar: {
		paddingRight: 24,
		backgroundColor: '#777777',
	},
	title: {
		flexGrow: 1,
		fontSize: '1rem',
		cursor: 'pointer',
	}
};

class Dashboard extends Component {

	state = {
		clientes: [],
		planos: [],
		planoAnchor: null,
		plano: { plano_id: 0, plano: 'Segmentados' },
		keyAccounts: [],
		keyAccountAnchor: null,
		keyAccount: { recurso_id_key_account: 0, nome: 'Todos' }
	}

	componentDidMount() {
		this.getPlanos();
		this.getKeyAccounts();
		this.getClientes();
	}

	isAuth = () => {
		return Fetch.token();
	};

	handleClickPlano = (event) => {
		this.setState({ planoAnchor: event.currentTarget })
	}

	handleClosePlano = () => {
		this.setState({ planoAnchor: null });
	}

	handleClickKeyAccount = (event) => {
		this.setState({ keyAccountAnchor: event.currentTarget })
	}

	handleCloseKeyAccount = () => {
		this.setState({ keyAccountAnchor: null });
	}

	getPlanos = () => {
		KmmService("M1506", "getPlanos", {}, response => {
			if (response.success) {
				this.setState({ planos: response.result.planos });
			}
		});
	}

	getKeyAccounts = () => {
		KmmService("M1506", "getKeyAccounts", {}, response => {
			if (response.success) {
				this.setState({ keyAccounts: response.result.key_accounts });
			}
		});
	}

	selectPlano = (plano) => {
		this.setState({ plano: plano, planoAnchor: null, timeOuts: [] }, this.getClientes);
	}

	selectKeyAccount = (keyAccount) => {
		this.setState({ keyAccount: keyAccount, keyAccountAnchor: null, timeOuts: [] }, this.getClientes);
	}

	getClientes = () => {
		KmmService("M1506", "getClientes", { "plano_id": this.state.plano.plano_id, "recurso_id_key_account" : this.state.keyAccount.recurso_id_key_account }, response => {
			if (response.success) {
					this.setState({ clientes: response.result.clientes });
			} else {
				if (this.isAuth()) {
					this.props.enqueueSnackbar(response.message, {
						variant: "error"
					});
				}
			}
			if (this.isAuth()) {
				setTimeout(this.getClientes, 60000);
			}
			else {
				this.props.onLogout();
			}
		});
	}

	render() {

		const { classes } = this.props;
		const { clientes } = this.state;
		return (
			<main className={classes.content}>
				<div className="dash-container">
					<div id="dashboard" className='dashboard'>
						<CssBaseline />
						<AppBar position="absolute" className={classes.appBar}>
							<Toolbar className={classes.toolbar}>
								<Menu
										anchorEl={this.state.planoAnchor}
										keepMounted
										open={Boolean(this.state.planoAnchor)}
										onClose={this.handleClosePlano}
									>
										{this.state.planos && this.state.planos.map(plano => 
											<MenuItem key={plano.plano} onClick={() => this.selectPlano(plano)}>
												{plano.plano}
											</MenuItem>
										)}
								</Menu>
								<Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
									Dashboard - 
									<span onClick={this.handleClickPlano}> Clientes {(this.state.plano && this.state.plano.plano && this.state.plano.plano) || 'não definida'}</span>
									<span onClick={this.handleClickKeyAccount}> - Key Account: {(this.state.keyAccount && this.state.keyAccount.nome && this.state.keyAccount.nome) || 'não definida'}</span>
								</Typography>

								<Menu
										anchorEl={this.state.keyAccountAnchor}
										keepMounted
										open={Boolean(this.state.keyAccountAnchor)}
										onClose={this.handleCloseKeyAccount}
									>
										{this.state.keyAccounts && this.state.keyAccounts.map(keyAccount => 
											<MenuItem key={keyAccount.nome} onClick={() => this.selectKeyAccount(keyAccount)}>
												{keyAccount.nome}
											</MenuItem>
										)}
								</Menu>
								<Button variant="contained" color="primary" onClick={this.props.onLogout}><ExitToApp /></Button>
							</Toolbar>
						</AppBar>

						<Grid container spacing={0}>
						{ 	clientes.map(function(cliente) {
								return (
									<Grid key={cliente.cliente_id} item lg={2} md={6} xs={12}>
										<ClienteCard key={cliente.cliente_id} className='clienteCard' cliente={cliente} />
									</Grid>
			  					)
							})
						}
						</Grid>				
					</div>
				</div>
			</main>
		);
	}
}

export default withStyles(styles)(withSnackbar(Dashboard));