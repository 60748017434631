import "./app.css";
import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { LoginService } from "../kmm-service/src/";
import Dashboard from "../dashboard/dashboad";
import Login from "../login/login";
import { withRouter } from 'react-router-dom';
import { Store, Fetch, Delete } from "../storage";

class App extends Component {
    isAuth = () => {
        return Fetch.token();
    };

    onLogin = (user, password) => {
        LoginService(user, password, response => {
            if (response.success && response.result) {
                this.props.enqueueSnackbar("Bem Vindo", {
                    variant: "success"
                });
                Store.token(response.result.token);
                this.forceUpdate();
            } else {
                this.props.enqueueSnackbar(response.message, {
                    variant: "error"
                });
            }
        });
    };

    onLogout = () => {
        Delete.token();
        this.forceUpdate();
    };

    render() {
        const queryString = require('query-string');
        let params = queryString.parse(this.props.location.search);
        
        if(params.equipe === undefined){
            params = {equipe: 'erp'};
        }
    
        return (
            <React.Fragment>
                {!this.isAuth() && <Login onLogin={this.onLogin} />}
                {this.isAuth() && <Dashboard equipe={params.equipe} user='' onLogout={this.onLogout} />}
            </React.Fragment>
        );
    }
}

export default withRouter(withSnackbar(App));
