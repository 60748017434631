import React from "react";
import { Card, CardContent, Typography, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = {
   Card: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      margin: "4px"
   },
   CardHeader: {
      padding: "16px",
      display: "flex",
      alignItems: "center",
      position: "relative"
   },
   iconHeader: {
      padding: "9px"
   },
   CardTitle: {
      color: '#FFFFFF',
		fontSize: '0.8rem',
   },
   CardSubTitle: {
      color: '#FFFFFF',
      fontSize: '0.7rem',
      position: 'absolute',
      right: '10px'
   },
   CardContent: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      paddingTop: "25px !important",
      paddingBottom: "6px !important",
      minHeight: '250px'
   },
   clickable: {
      cursor: "pointer",
		"&:hover": {
			opacity: 0.5
		}
   },
   IconButton: {
      fill: "white",
      color: "white",
      padding: "4px"
   }
}

export const DashCard = withStyles(styles)((props) => {

   const id = (props && props.id) || "card";
   const children = (props && props.children) || null;
   const classes = (props && props.classes) || {};
   const onClick = (props && props.onClick) || null;
   const title = (props && props.title) || "";
   const subtitle = (props && props.subtitle) || "";
   const details = (props && props.details) || null;
   const color = (props && props.color) || "#1976d2";
   const icon = (props && props.icon) || null;
   const iconClick = (props && props.iconClick) || null;

   return (
      <Card id={id} onClick={onClick} className={`${classes.Card} ${(onClick && classes.clickable) || ""}`}>
         <div style={{backgroundColor: color}} className={`${classes.CardHeader} ${icon ? classes.iconHeader:''}`}>
            {icon && <IconButton className={classes.IconButton} onClick={iconClick}>{icon}</IconButton>}
            <Typography className={classes.CardTitle}>{title}</Typography>
            {subtitle ? 
               <Typography className={classes.CardSubTitle}>{subtitle}</Typography> 
            : ''}
            {details && <Typography className={classes.CardSubTitle}>{details}</Typography>}
         </div>
         <CardContent className={classes.CardContent}>
            {children}
         </CardContent>
      </Card>
   )
});

export default DashCard;
