import React, { useState } from 'react';
import moment from "moment";
import { Typography } from "@material-ui/core";
import Chart from "react-apexcharts";
import GaugeChart from 'react-gauge-chart'
import { withStyles } from "@material-ui/core/styles";
import KmmService from "../../kmm-service/src/";

import { DashCard, DashPopover, DashTable } from "../components/components";

const styles = {
   value: {
      fontSize: '5.5rem',
      color: '#444444'
   },
   valueDetail: {
      fontSize: '3.5rem',
      color: '#d32f2f'
   },
   titleAtendimentos : {
      fontSize: '12px',
      fontWeight: 'bold',
      color: '#666666',
      marginTop: '20px',
   }
}
export const ClienteCard = withStyles(styles)((props) => {

   const [anchorEl, setAnchorEl] = useState(null);
   const [atendimentos, setAtendimentos] = useState([]);
   const [title, setTitle] = useState("Atendimentos");

   const cliente = (props && props.cliente) || {};
   const classes = (props && props.classes) || {};

   const columns = [
      {
         identifier: "num_protocolo",
         label: "Protocolo",
         variant: row => handleColumnVariant(row)
      },
      {
         identifier: "titulo",
         label: "Título",
         variant: row => handleColumnVariant(row)
      },
      {
         identifier: "suporte_categoria",
         label: "Categoria",
         variant: row => handleColumnVariant(row)
      },
      {
         identifier: "severidade",
         label: "Severidade",
         variant: row => handleColumnVariant(row)
      },
      {
         identifier: "status",
         label: "Status",
         variant: row => handleColumnVariant(row)
      },
      {
         identifier: "data_abertura",
         label: "Data Abert.",
         variant: row => handleColumnVariant(row),
         format: value => (value && moment(value).format("DD/MM/YYYY hh:mm")) || '---'
      },
      {
         identifier: "alterado_ha",
         label: "Alterado há",
         variant: row => handleColumnVariant(row)
      },
      {
         identifier: "sla_geral",
         label: "SLA Geral",
         variant: row => handleColumnVariant(row)
      }
   ];

   function handleColumnVariant(row) {
      if(row.sla_geral === 'ATRASADO'){
         return "warning";
      }else if (row.i > parseFloat(cliente.max_ats)) {
         return "danger";
      }else {
         return "default";
      }
   }

   function handlePopoverClose() {
      setAnchorEl(null);
      setAtendimentos([]);
   }

   const chart = {
      series: [{
         name: 'Dentro do Ideal',
         data: [parseFloat(cliente.ats) <= parseFloat(cliente.max_ats) ? parseFloat(cliente.ats) : parseFloat(cliente.max_ats)]
      }, {
         name: 'Fora do Ideal',
         data: [parseFloat(cliente.ats) > parseFloat(cliente.max_ats) ? parseFloat(cliente.ats) - parseFloat(cliente.max_ats) : 0]
      }, {
         name: 'Margem',
         data: [parseFloat(cliente.ats) <= parseFloat(cliente.max_ats) ? parseFloat(cliente.max_ats) - parseFloat(cliente.ats) : 0]
      }],
      options: {
         chart: {
            type: 'bar',
            height: 10,
            stacked: true,
            toolbar: { show: false },
            stroke: false,
            offsetX: -10,
            offsetY: -20
         },
         colors: ["#4472C4", '#d32f2f', '#EFEFEF', '#FFFFFF'],
         plotOptions: {
            bar: {
               horizontal: true,
            },
         },
         stroke: {
            width: 0,
            colors: ['#FFFFFF']
         },
         yaxis: {
            min: 0,
            labels: { style: { fontSize: '11px' } },
            axisBorder: {
               show: false
            },
         },
         xaxis: {
            categories: [''],
            labels: {
               show: false,
               hideOverlappingLabels: true,
               maxHeight: 0,
               trim: true,
            },
            axisBorder: {
               show: false
            },
            axisTicks: {
               show: false
            },
            crosshairs: {
               show: false,
            }
         },
         fill: {
            opacity: 1
         },
         legend: {
            show: false
         },
         dataLabels: {
            enabled: [true, true, false],
            style: {
               fontSize: '12px',
               fontWeight: 'bold',
               colors: ['#FFFFFF', '#FFFFFF', '#CCCCCC']
            }
         }
      }
   }

   function getClienteAtendimentos(event) {
      //console.log(event.target);
      const target = event.target;
      //const target = "AT_"+cliente.cliente_id;
      const nomeCliente = cliente.cliente;
		KmmService("M1506", "getClienteAtendimentos", { "cliente_id": cliente.cliente_id }, response => {
			if (response.success) {
            setAtendimentos(response.result.atendimentos);
         }
         setAnchorEl(target);
         setTitle(`Atendimentos - ${nomeCliente}`)
		});
   }

   return (
      <React.Fragment>
         <DashCard id={cliente.nome} color={cliente.alerta === '1' ? '#d32f2f' : ''} title={cliente.cliente} subtitle={cliente.key_account}>

            <div className="mixed-chart" id={"mixed_"+cliente.nome} >
               
               <GaugeChart id={"gauge-chart_"+cliente.cliente_id}
                  nrOfLevels={420}
                  arcsLength={[
                        (parseInt(cliente.sla_meta) / 100), 
                        0.0, 
                        1 - (parseInt(cliente.sla_meta) / 100)
                  ]}
                  colors={['#EA4228', '#F5CD19', '#5BE12C']}
                  textColor={'#444444'}
                  needleColor={"#464A4F"}
                  needleBaseColor={"#464A4F"}
                  percent={cliente.sla / 100}
                  cornerRadius={2} 
                  arcPadding={0.007}
                  formatTextValue={
                     function(val){
                        return "SLA: "+val+"%"
                     }
                  }
               />

               <div onClick={getClienteAtendimentos}>

                  <Typography align="center" className={classes.titleAtendimentos}>ATENDIMENTOS</Typography>
                  
                  <Chart
                     options={chart.options}
                     series={chart.series}
                     type="bar"
                     id={"ATS_"+cliente.cliente_id}
                     height={80}
                  />
               </div>

               <DashPopover anchor={anchorEl} onClose={handlePopoverClose} title={title}>
                  <DashTable columns={columns} rows={atendimentos} emptyMessage="Nenhum atendimento para visualização" />
               </DashPopover>

            </div>

         </DashCard>
      </React.Fragment>
   )
});

export default ClienteCard;

// <br />
/*<div className="mixed-chart">
<Chart
options={chart.options}
series={chart.series}
type="bar"
height={100}
/>
</div> */